import React from 'react';
import { InlineWidget } from 'react-calendly';

const CalendlyInline = () => {
  return (
    <div className="inline-widget">
      <InlineWidget url="https://calendly.com/spjohn85/30min?embed_type=Inline&embed_domain=1&back=1&hide_gdpr_banner=1" />
    </div>
  );
};

export default CalendlyInline;